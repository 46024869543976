import { convertToUTCForBackend, createAppointment, useAuth, deleteReminder, Response, Reminder  } from '@aposphaere/core-kit'
import { toast, EditIcon } from '@aposphaere/ui-components'
import { Formik, Form } from 'formik'
import React, { useState, useMemo, useEffect, useRef } from 'react'
import { format, addHours, parseJSON } from 'date-fns'
import * as Yup from 'yup'
import { useAuthenticatedMutation } from '../../hooks/useAuthenticatedMutation'
import { StepStates, useCalendarPanel } from './CalendarPanelContext'
import { Navigation } from './Navigation'
import { useAppointmentsQuery, useAppointmentTypesQuery, usePharmacyQuery, useStatusesQuery, useTasksQuery } from '../../hooks/graphql'
import AppointmentDetailsEditor from './AppointmentDetailEditor'
import { useRoutePlanner } from '../../contexts/routePlannerContext'

export type AppointmentFormValues = {
  pharmacyId?: number
  name: string
  contactPerson: string
  appointmentTypeId: string
  statusId: string
  note: string
  sendConfirmation: boolean
  customEmail?: string
}

const ScheduleForm = ({
  pharmacyId,
  onClose,
  setRouteForAppointment,
}: {
  pharmacyId: number | undefined
  onClose: () => void
  setRouteForAppointment: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { selectedOrderItems, selectedTime, selectedQuarter, activeState, isOccupied, goTo, setSelectedOrderItems } = useCalendarPanel()
  const [isCustomEmail] = useState<boolean>(false)
  const auth = useAuth()
  const { toggleShowSelectedDayOverview, setRouteResponse, setSelectedDay } = useRoutePlanner()
  const divRef = useRef<HTMLDivElement | null>(null)

  function scrollToTop() {
    if (divRef.current && divRef?.current.scrollIntoView) {
      divRef.current.scrollIntoView()
    }
  }

  const { data: pharmacy, refetch: refetchPharmacy } = usePharmacyQuery(pharmacyId)
  const { refetch: refetchAppointments } = useAppointmentsQuery()
  const appointmentTypesQuery = useAppointmentTypesQuery()
  const appointmentTypes = appointmentTypesQuery.data?.appointmentTypes
  const deleteNoteMutation = useAuthenticatedMutation(deleteReminder)

  const statusesQuery = useStatusesQuery()
  const statuses = statusesQuery.data
  const { data: tasks } = useTasksQuery()
  const tasksQuery = useTasksQuery()

  const mutationCreate = useAuthenticatedMutation(createAppointment)

  useEffect(() => {
    scrollToTop()
  }, [])

  const onFormSubmit = async (values: AppointmentFormValues) => {
    if (!selectedQuarter) {
      // eslint-disable-next-line no-console
      console.debug('No valid quarter found')
    }

    function parseUTC(date: string | number | Date): Date {
      if (date instanceof Date) {
        return date
      }
      if (typeof date === 'string') {
        return parseJSON(date)
      }
      return new Date(date)
    }
    const appointmentVariables = {
      pharmacy_id: pharmacyId?.toString(),
      quarter_id: selectedQuarter?.id,
      status_id: values.statusId,
      appointment_type_id: values.appointmentTypeId,
      creator_id: auth.user?.id,
      date: format(parseUTC(selectedTime).getTime(), "yyyy-MM-dd'T'HH:mm:ss'.000Z'"),
      note: values.note ?? null,
      send_confirmation: values.sendConfirmation,
      order_items: selectedOrderItems.map((item) => ({
        project_id: item.project_id,
        amount: Number(item.amount),
      })),
      id: undefined,
      confirmation_email_address: isCustomEmail ? values.customEmail : pharmacy?.email,
      contact_person: values.contactPerson,
    }
    try {
      await mutationCreate(appointmentVariables)

      const pharmacyTasks: Reminder[] = tasks?.filter((reminder) => reminder.pharmacy?.id === pharmacyId) || []

      const getMutation = (reminder: Reminder) => {
        let mutation: (() => Promise<Response<unknown>>) | null = null
        mutation = () => deleteNoteMutation({ id: reminder.id })
        return mutation
      }

      const onDeleteReminder = async (reminder: Reminder) => {
        const adjustedValues = {
          ...reminder,
          until: convertToUTCForBackend(reminder.until) ?? undefined,
        }
        const mutation = getMutation(adjustedValues)
        const reminderResponse = await mutation()
        await tasksQuery.refetch()
        if (reminderResponse?.errors !== undefined) {
          alert(reminderResponse.errors)
          return
        }
      }

      const deleteReminders = async () => {
        for (const reminder of pharmacyTasks || []) {
          await onDeleteReminder(reminder)
        }
      }

      deleteReminders()

      await refetchPharmacy()
      void refetchAppointments()
      onClose()
      setRouteForAppointment(false)
      toggleShowSelectedDayOverview()
      goTo(StepStates.DateSelection)
      setRouteResponse(null)
      setSelectedOrderItems([])
      setSelectedDay(undefined)
      toast.show({
        headline: 'Termin erfolgreich angelegt',
        type: 'success',
      })
    } catch (error: unknown) {
      toast.show({
        headline: 'Zu dieser Zeit ist kein Termin möglich.',
        type: 'error',
      })
    }
  }
  const intialFormValues: AppointmentFormValues = {
    pharmacyId: pharmacy ? pharmacy.id : undefined,
    name: pharmacy ? pharmacy.name || '' : '',
    contactPerson: '',
    appointmentTypeId: appointmentTypes ? appointmentTypes[0].id.toString() : '',
    statusId: statuses?.forAppointments?.length ? statuses?.forAppointments[0].id.toString() : '',
    note: '',
    sendConfirmation: pharmacy?.email ? true : false,
  }

  const AppointmentValidationScheme = useMemo(
    () =>
      Yup.object().shape({
        contactPerson: Yup.string(),
        appointmentTypeId: Yup.string().required('Bitte auswählen'),
        statusId: Yup.string().required('Bitte auswählen'),
        customEmail: isCustomEmail ? Yup.string().email().required('Pflichtfeld') : Yup.string(),
        send_confirmation: Yup.boolean(),
        email: !isCustomEmail ? Yup.string().email('Ungültige E-Mail, bitte wählen Sie eine eigene aus') : Yup.string().nullable().notRequired(),
      }),
    [isCustomEmail],
  )

  const isValidEmail = useMemo(() => {
    const emailPattern = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,4}$/
    return emailPattern.test(pharmacy?.email || '')
  }, [pharmacy])
  return (
    <div>
      <Formik key="appointment-creation-form" initialValues={intialFormValues} onSubmit={onFormSubmit} validationSchema={AppointmentValidationScheme}>
        {({ values, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <div className={`flex shadow-scrollarea flex-wrap sticky bg-white self-end bottom-0 w-full h-full place-items-stretch overflow-scroll`}>
              <div ref={divRef} className="w-full justify-between items-center py-2 pl-6 pr-4 sticky top-0 z-50 bg-white border-b border-gray-300">
                <p className={`text-md font-medium font-body pt-1 text-blue-700`}>{`Termin:`}</p>
                <div className={`text-md text-black-200 font-normal font-body pt-1 flex mb-5`}>
                  <span className="mr-1">{format(selectedTime, 'dd.MM.yyyy')}</span> |
                  <span className="ml-1">
                    {format(selectedTime, 'HH:mm')} - {format(addHours(selectedTime, 1), 'HH:mm')}
                  </span>
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      goTo(StepStates.DateSelection)
                    }}
                  >
                    <EditIcon dimension="26" />
                  </span>
                </div>
              </div>
              <div className="flex w-full justify-between items-center py-2 pl-6 pr-4 sticky top-0 z-50 bg-white ">
                <div className="flex items-center">
                  <p className={`text-md font-medium font-body pt-1 text-blue-700`}>{`Weitere Angaben zum Termin:`}</p>
                </div>
              </div>
              <AppointmentDetailsEditor selectedDay={selectedTime} selectedPharmacyId={pharmacyId} />
              <div className="flex w-full border-t bg-white border-gray-300 h-20 self-end" />
            </div>
            <Navigation
              disabled={
                isSubmitting ||
                isOccupied ||
                (values.sendConfirmation ? !isValidEmail && !values.customEmail : false) ||
                (!selectedOrderItems?.length && Number(values.statusId) === 2 && values.appointmentTypeId === '1')
              }
              step={activeState}
              onClose={onClose}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ScheduleForm
