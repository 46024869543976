import { Button, ButtonKind, InputLabel, InputMessage, InputMessageKind, Modal, ModalKind, toast, TextAreaInput } from '@aposphaere/ui-components'
import { Formik } from 'formik'
import React from 'react'
import { updateContactPersonPharmacy } from '@aposphaere/core-kit'
import { useModal } from '../../contexts/modalContext'
import { usePharmacyQuery } from '../../hooks/graphql'
import { useAuthenticatedMutation } from '../../hooks/useAuthenticatedMutation'

type ContactPersonFormValues = {
  pharmacyId?: number
  contact_peron: string
}

type Props = {
  pharmacyId?: number
}

const UpdateContactModal: React.FC<Props> = ({ pharmacyId }) => {
  const { closeModal } = useModal()

  const { data: pharmacy, refetch: refetchPharmacy } = usePharmacyQuery(pharmacyId)

  const updateContactPerson = useAuthenticatedMutation(updateContactPersonPharmacy)

  const getMutation = (values: ContactPersonFormValues) => {
    const defaultVariables = {
      id: values.pharmacyId,
      contact_person: values.contact_peron,
    }

    return () =>
      updateContactPerson({
        ...defaultVariables,
      })
  }

  const initialFormValues: ContactPersonFormValues = {
    pharmacyId: pharmacy ? pharmacy.id : undefined,
    contact_peron: pharmacy?.contact_person || '',
  }

  const onFormSubmit = async (values: ContactPersonFormValues) => {
    const mutation = getMutation(values)

    if (!mutation) {
      return
    }

    const noteResponse = await mutation()
    if (noteResponse.errors !== undefined) {
      alert(noteResponse.errors)
      return
    }
    const { notificationText } = getTextObject()
    try {
      await refetchPharmacy()
      toast.show({
        headline: notificationText,
        type: 'success',
      })
    } catch {
      toast.show({
        headline: 'Daten konnten nicht aktualisiert werden',
        type: 'error',
      })
    }

    closeModal()
  }

  const getTextObject = () => {
    return {
      titleModalText: 'Kontaktperson speichern',
      submitButtonText: 'Kontaktperson speichern',
      notificationText: 'Kontaktperson erfolgreich gespeichert',
    }
  }

  return (
    <Modal kind={ModalKind.sm} title={'Kontaktperson ändern'} onClose={closeModal} onBack={() => null}>
      <Formik key="note-creation-form" initialValues={initialFormValues} onSubmit={onFormSubmit}>
        {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
          <div className="flex flex-wrap w-full">
            <div className="flex flex-wrap w-full">
              <div className="w-full p-4">
                <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <InputLabel>{'Kontaktperson:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextAreaInput
                        initialValue={values.contact_peron}
                        placeholder=""
                        textareaCustomStyle="form-input font-body block w-full h-10 bg-gray-100 rounded-md px-4 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400 sm:text-base sm:leading-5"
                        onChange={(event: React.FormEvent<HTMLTextAreaElement>) => setFieldValue('contact_peron', event.currentTarget.value)}
                      />
                      {errors.contact_peron && touched.contact_peron ? (
                        <InputMessage kind={InputMessageKind.error}>{errors.contact_peron}</InputMessage>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-between p-4 pt-8 pb-6 place-items-stretch">
              <Button kind={ButtonKind.outlinedSecondary} onClick={closeModal}>
                {'Abbrechen'}
              </Button>
              <Button kind={ButtonKind.primary} onClick={handleSubmit} disabled={isSubmitting}>
                {getTextObject().submitButtonText}
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  )
}

export default UpdateContactModal