import {
  getPharmacyByPharmacyCluster,
  getPharmacyDetails,
  getPharmacySearchResults,
  addCallToPharmacy,
  updateOpeningTimes,
  Pharmacy,
  PharmacyResponse,
  ReminderState,
  updatePharmacyForCallCenter,
  updatePharmacyStatus,
  useGqlClient,
} from '@aposphaere/core-kit'
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query'
import { DeactivatePharmacyVariables } from '../../components/DeactivatePharmacyModal'
import { useCrmContext } from '../../contexts/crmContext'
import { QueryOptions } from './utils'
import { toast } from '@aposphaere/ui-components'

const getPharmaciesCacheKey = (quarterId: number | string, clusterId: number | string) => [
  'pharmacies',
  `quarter-${String(quarterId)}`,
  `cluster-${String(clusterId)}`,
]

export const getPharmacyCacheKey = (id: string | number) => ['pharmacy', String(id)]

/*
 * QUERIES
 */

type PharmaciesQueryProps = {
  options?: QueryOptions<Pharmacy[]>
  clusterId?: string | number
}

type PharmacyId = {
  pharmacy_id: string
}

type PharmacyOpeningTimesProps = {
  pharmacyId: string
  openingTimes: string
}

type PharmacySearch = {
  options?: QueryOptions<PharmacySearchResults[]>
  search: string
}

type PharmacySearchResults = {
  id: string
  name: string
  okid: string
}

export const usePharmaciesQuery = (props?: PharmaciesQueryProps) => {
  const gqlClient = useGqlClient()
  const { activeQuarter, activeCluster } = useCrmContext()
  const pharmacylusterId = activeCluster.id ?? props?.clusterId

  return useQuery(
    getPharmaciesCacheKey(activeQuarter.id, pharmacylusterId),
    async () => {
      const data = await gqlClient.request<{ pharmacies: Pharmacy[] }>(getPharmacyByPharmacyCluster, {
        quarterId: activeQuarter.id,
        pharmacyClusterId: pharmacylusterId,
      })
      if (!data?.pharmacies) {
        return []
      }
      return data.pharmacies
        .filter((pharmacy) => pharmacy.address)
        .map((pharmacy) => ({
          ...pharmacy,
          tasks: pharmacy?.tasks?.filter((task) => task.state === ReminderState.Active),
        }))
    },
    { staleTime: Infinity, ...props?.options },
  )
}

export const usePharmacyQuery = (id: number | undefined | string, options?: QueryOptions<Pharmacy>) => {
  const gqlClient = useGqlClient()
  const queryClient = useQueryClient()
  const { activeQuarter, activeCluster } = useCrmContext()

  return useQuery<Pharmacy>(
    ['pharmacy', String(id)],
    async () => {
      const response = await gqlClient.request<PharmacyResponse>(getPharmacyDetails, { id, quarterId: activeQuarter.id })
      const data = response?.pharmacy
      const modifiedResults: Pharmacy = {
        ...data,
        tasks: data?.tasks?.filter((task) => task.state === ReminderState.Active),
      }

      return modifiedResults
    },
    {
      enabled: !!id,
      onSettled: (pharmacy) => {
        if (!pharmacy) {
          return
        }
        updatePharmacyListInCache({
          queryClient,
          pharmacy,
          quarterId: activeQuarter.id,
          clusterId: activeCluster.id,
        })
      },
      ...options,
    },
  )
}

export const usePharmaciesSearchResults = (props?: PharmacySearch) => {
  const gqlClient = useGqlClient()
  const search = props?.search

  return useQuery(
    ['getPharmacySearchResults', search],
    async () => {
      const data = await gqlClient.request<{ searchPharmacy: PharmacySearchResults[] }>(getPharmacySearchResults, { search })
      if (!data?.searchPharmacy) {
        return []
      }
      return data.searchPharmacy
    },
    { staleTime: Infinity, ...props?.options },
  )
}

/*
 * MUTATIONS
 */

interface AvailableForCallcenterMutationVariables {
  id: number
  available_for_callcenter: boolean
}

export const useAvailableForCallcenterMutation = (options?: QueryOptions<unknown>) => {
  const gqlClient = useGqlClient()
  const queryClient = useQueryClient()
  const { activeQuarter, activeCluster } = useCrmContext()
  const cacheId = getPharmaciesCacheKey(activeQuarter.id, activeCluster.id)

  return useMutation(async (variables: AvailableForCallcenterMutationVariables) => gqlClient.request(updatePharmacyForCallCenter, variables), {
    onMutate: async (variables) => {
      const pharmacyCacheId = getPharmacyCacheKey(variables.id)
      await Promise.all([queryClient.cancelQueries(cacheId), queryClient.cancelQueries(pharmacyCacheId)])

      queryClient.setQueryData<Pharmacy | undefined>(pharmacyCacheId, (oldPharmacy) => {
        if (!oldPharmacy) {
          return undefined
        }
        if (oldPharmacy.id === variables.id) {
          return { ...oldPharmacy, available_for_callcenter: variables.available_for_callcenter }
        }
        return oldPharmacy
      })

      queryClient.setQueryData<Pharmacy[] | undefined>(cacheId, (oldPharmacies) => {
        if (!oldPharmacies) {
          return undefined
        }
        return oldPharmacies.map((pharmacy) => {
          if (pharmacy.id === variables.id) {
            return { ...pharmacy, available_for_callcenter: variables.available_for_callcenter }
          }
          return pharmacy
        })
      })
    },
    onSettled: () => queryClient.invalidateQueries(cacheId),
    ...options,
  })
}

interface UpdatePharmacyListInCacheParams {
  pharmacy: Pharmacy
  quarterId: number
  clusterId: number
  queryClient: QueryClient
}

const updatePharmacyListInCache = ({ pharmacy: fetchedPharmacy, quarterId, clusterId, queryClient }: UpdatePharmacyListInCacheParams) => {
  const pharmacyListCacheKey = getPharmaciesCacheKey(quarterId, clusterId)
  const cachedPharmacies = queryClient.getQueryData<Pharmacy[]>(pharmacyListCacheKey)
  if (cachedPharmacies && fetchedPharmacy) {
    const updatedPharmacies = cachedPharmacies.map((_pharmacy) =>
      _pharmacy.id === fetchedPharmacy.id ? { ..._pharmacy, ...fetchedPharmacy } : _pharmacy,
    )
    queryClient.setQueryData(pharmacyListCacheKey, updatedPharmacies)
  }
}

export const useUpdatePharmacyStatusMutation = (options?: QueryOptions<unknown>) => {
  const gqlClient = useGqlClient()
  const queryClient = useQueryClient()
  const { activeQuarter, activeCluster } = useCrmContext()
  const cacheId = getPharmaciesCacheKey(activeQuarter.id, activeCluster.id)
  return useMutation(async (variables: DeactivatePharmacyVariables) => gqlClient.request(updatePharmacyStatus, variables), {
    onMutate: async (variables) => {
      if (variables?.pharmacyId) {
        await Promise.all([queryClient.cancelQueries(cacheId), queryClient.cancelQueries(getPharmacyCacheKey(variables.pharmacyId))])

        queryClient.setQueryData<Pharmacy[] | undefined>(cacheId, (oldPharmacies) => {
          if (!oldPharmacies) {
            return undefined
          }
          return oldPharmacies.map((pharmacy) => {
            if (pharmacy.id === variables.pharmacyId) {
              return { ...pharmacy, pharmacy_status: variables.pharmacyStatus }
            }
            return pharmacy
          })
        })

        queryClient.setQueryData<Pharmacy | undefined>(cacheId, (oldPharmacy) => {
          if (!oldPharmacy) {
            return undefined
          }

          if (oldPharmacy.id === variables.pharmacyId) {
            return { ...oldPharmacy, pharmacy_status: variables.pharmacyStatus }
          }
          return oldPharmacy
        })
      }
    },
    onSettled: () => queryClient.invalidateQueries(cacheId),
    ...options,
  })
}

export const useAddCallToPharmacy = () => {
  const gqlClient = useGqlClient()
  const queryClient = useQueryClient()
  const addCallToPharmacyId = async (variables: PharmacyId) => await gqlClient.request(addCallToPharmacy, variables)
  return useMutation({
    mutationFn: addCallToPharmacyId,
    onSuccess: () => {
      toast.show({
        headline: 'Der letzte Aufruf wurde hinzugefügt',
        type: 'success',
      })
      queryClient.invalidateQueries('pharmacy')
    },
  })
}

export const useUpdateOpeningTimes = () => {
  const gqlClient = useGqlClient()
  const queryClient = useQueryClient()
  const updateOpeningTimesId = async (variables: PharmacyOpeningTimesProps) => await gqlClient.request(updateOpeningTimes, variables)
  return useMutation({
    mutationFn: updateOpeningTimesId,
    onSuccess: () => {
      toast.show({
        headline: 'Die Öffnungszeiten wurden aktualisiert',
        type: 'success',
      })
      queryClient.invalidateQueries('pharmacy')
    },
    onError: () => {
      console.log('error')
    },
  })
}
