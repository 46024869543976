import React from 'react'
import { InputSearchNoResults, InputSearchSuggestions } from '@aposphaere/ui-components'

type PharmacySearchResults = {
  id: string
  name: string
  okid: string
}

type Props = {
  showSearchSuggestions: boolean
  onSearchSuggestionClicked: (pharmacy: PharmacySearchResults) => void
  pharmacies: PharmacySearchResults[]
}

const ParentSearchPanel = ({ showSearchSuggestions, onSearchSuggestionClicked, pharmacies }: Props) => {
  const resultStyle = 'w-full h-fit my-0 rounded text-base p-2 cursor-pointer hover:bg-blue-50'

  if (showSearchSuggestions) {
    return !pharmacies.length ? (
      <InputSearchNoResults />
    ) : (
      <InputSearchSuggestions>
        {pharmacies.map((pharmacy) => (
          <div key={pharmacy.id} className={resultStyle} onClick={() => onSearchSuggestionClicked(pharmacy)}>
            {`${pharmacy.name || ''}, ${pharmacy.okid || ''}`}
          </div>
        ))}
      </InputSearchSuggestions>
    )
  }

  return null
}

export default ParentSearchPanel