import { DateType } from '../contexts/dashboardContext'
import { useAppointmentsQuery } from './graphql'
import { format } from 'date-fns'
import { WHOLE_GERMANY_ID } from '../pages/DashboardPage/constants'
import { Appointment, Project, Pharmacy, User } from '@aposphaere/core-kit'
import { useFinishedPresentationsQuery } from './graphql/finishedPresentation'

export type DataForDownload = {
  appointmentsForDownload: Appointment[]
  pharmaciesForDownload: PharmaciesForDownload[]
  finishedPresentationsForDownload: FinishedPresentation[]
  slotsForDownload: FinishedPresentation[]
}

export type PharmaciesForDownload = {
  id: number
  name: string
  address: string
  zipcode: string
  city: string
  classification: string
  referent: string
  projects: string[]
  appointments: string[]
  appointmentsCounter: number
  productCounter: number
}

export interface FinishedPresentation {
  id: number
  starttime: number
  number_of_participants: number
  device_count: number
  project: Project
  pharmacy: Pharmacy
  appointment: Appointment
  trainer: User
  erstschulung: boolean
  time_spent?: string
  adjusted_number_of_participants: number
}

export const useDataDashboardsDownload = (clusterSelected: string, dates: DateType) => {
  const variables = {
    enabled: clusterSelected !== WHOLE_GERMANY_ID,
    date: {
      from: format(dates.startDate, 'yyyy-MM-dd'),
      to: format(dates.endDate, 'yyyy-MM-dd'),
    },
    clusterId: clusterSelected,
  }

  if (clusterSelected !== WHOLE_GERMANY_ID) {
    variables.clusterId = clusterSelected
  }

  const { data: appointmentsFromQuery } = useAppointmentsQuery({ ...variables })
  const { data: finishedPresentationsFromQuery } = useFinishedPresentationsQuery({ ...variables })

  const appointmentsForDownload: Appointment[] = appointmentsFromQuery ? appointmentsFromQuery : []
  const finishedPresentationsForDownload: FinishedPresentation[] = finishedPresentationsFromQuery ? finishedPresentationsFromQuery : []

  const pharmacyData: any = {}

  appointmentsFromQuery?.forEach((appointment) => {
    const pharmacyId = appointment.pharmacy?.id

    if (pharmacyId) {
      if (!pharmacyData[pharmacyId]) {
        pharmacyData[pharmacyId] = {
          id: pharmacyId,
          name: appointment.pharmacy?.name,
          address: appointment.pharmacy?.address.address_name,
          zipcode: appointment.pharmacy?.address.zipcode,
          city: appointment.pharmacy?.address.city,
          classification: appointment.pharmacy?.classification,
          referent: appointment.creator?.name,
          projects: [],
          appointments: [],
          appointmentsCounter: 0,
          productCounter: 0,
        }
      }

      appointment.order_items.forEach((orderItem) => {
        if (orderItem.project) {
          pharmacyData[pharmacyId].projects.push(orderItem.project.name)
        }
      })

      if (appointment.date) {
        const formattedDate = format(new Date(appointment.date), 'dd.MM.yyyy')
        pharmacyData[pharmacyId].appointments.push(formattedDate)
      }

      pharmacyData[pharmacyId].appointmentsCounter++
      pharmacyData[pharmacyId].productCounter += appointment.order_items.length
    }
  })

  const pharmaciesForDownload: PharmaciesForDownload[] = Object.values(pharmacyData)

  const slotsForDownload: FinishedPresentation[] = finishedPresentationsForDownload.filter((presentation) => presentation.erstschulung === true)

  return {
    appointmentsForDownload,
    pharmaciesForDownload,
    finishedPresentationsForDownload,
    slotsForDownload,
  }
}
