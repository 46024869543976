import { Button, ButtonKind, InputLabel, Modal, ModalKind, TextInput } from '@aposphaere/ui-components'
import { Formik } from 'formik'
import React from 'react'
import { useModal } from '../../contexts/modalContext'
import { usePharmacyQuery } from '../../hooks/graphql'
import { Checkbox, PlusSignIcon, IconButtonKind, IconButton, DeleteIcon } from '@aposphaere/ui-components'
import { useUpdateOpeningTimes } from '../../hooks/graphql'

interface Props {
  pharmacyId: number
}

const PharmacyUpdateModal: React.FC<Props> = ({ pharmacyId }) => {
  const { data: currentlyActivePharmacy, refetch: refetchPhamracy } = usePharmacyQuery(pharmacyId)
  const { closeModal } = useModal()

  interface OpeningTime {
    weekday: number
    slots: string[][]
    closed: boolean
  }

  const WeekdayNames: { [key: number]: string } = {
    1: "Montag",
    2: "Dienstag",
    3: "Mittwoch",
    4: "Donnerstag",
    5: "Freitag",
    6: "Samstag",
    7: "Sonntag",
  }

  const defaultOpeningTimes = JSON.stringify([
    {"weekday": 1, "slots": [["", ""], ["", ""]]},
    {"weekday": 2, "slots": [["", ""], ["", ""]]},
    {"weekday": 3, "slots": [["", ""], ["", ""]]},
    {"weekday": 4, "slots": [["", ""], ["", ""]]},
    {"weekday": 5, "slots": [["", ""], ["", ""]]},
    {"weekday": 6, "slots": [["", ""], ["", ""]]},
    {"weekday": 7, "slots": [["", ""], ["", ""]]},
  ])

  const openingTimesJson: string = (currentlyActivePharmacy?.opening_times && currentlyActivePharmacy.opening_times.length > 2) 
  ? currentlyActivePharmacy.opening_times
  : defaultOpeningTimes

  const initialOpeningTimes: OpeningTime[] = JSON.parse(openingTimesJson).map((time: OpeningTime) => ({
    ...time,
    closed: false,
  }))

  const { mutate: updateOpeningTimesId } = useUpdateOpeningTimes()

  const handleAddSlot = (dayIndex: number, setFieldValue: (field: string, value: any) => void, values: OpeningTime[]) => {
    const updatedTimes = [...values]
    updatedTimes[dayIndex].slots.push(['', ''])
    setFieldValue('openingTimes', updatedTimes)
  }

  const handleDeleteSlot = (dayIndex: number, setFieldValue: (field: string, value: any) => void, values: OpeningTime[]) => {
    const updatedTimes = [...values]
    const updatedSlots = updatedTimes[dayIndex].slots.slice(0, -1)
    updatedTimes[dayIndex].slots = updatedSlots
    setFieldValue('openingTimes', updatedTimes)
  }

  const handleCheckboxChange = (dayIndex: number, setFieldValue: (field: string, value: any) => void, values: OpeningTime[]) => {
    const updatedTimes = [...values]
    updatedTimes[dayIndex].closed = !updatedTimes[dayIndex].closed
    setFieldValue('openingTimes', updatedTimes)
  }

  return (
    <Modal kind={ModalKind.m} title={'Öffnungszeiten bearbeiten'} onClose={closeModal} onBack={() => null}>
      <Formik
        key="pharmacy-updated-form"
        initialValues={{ openingTimes: initialOpeningTimes }}
        enableReinitialize
        onSubmit={async (values) => {
          const updatedOpeningTimes = values.openingTimes.filter(time => !time.closed)
          const updatedOpeningTimesJson = JSON.stringify(updatedOpeningTimes)

          if (currentlyActivePharmacy?.id) {
            await updateOpeningTimesId({
              pharmacyId: currentlyActivePharmacy.id.toString(),
              openingTimes: updatedOpeningTimesJson
            })
          } else {
            console.error("Pharmacy ID is undefined.")
          }
          closeModal()
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <div className="flex flex-wrap w-full">
            <div className="w-full p-4">
              {values.openingTimes.map(({ weekday, slots, closed }: OpeningTime, dayIndex: number) => (
                <div key={weekday} className={`w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 py-2`}>
                  <div className="sm:col-span-1 flex">
                    <InputLabel>{WeekdayNames[weekday] + ":"}</InputLabel>
                  </div>
                  <div className="sm:col-span-2 flex flex-col gap-2">
                    {slots.map((slot, slotIndex) => (
                      <div key={slotIndex} className="rounded-md -mt-1 flex gap-2">
                        {slot.map((time, timeIndex) => {
                          const displayTime = time.slice(0, 5)
                          return (
                            <TextInput
                              key={timeIndex}
                              type="text"
                              value={displayTime}
                              disabled={closed}
                              onChange={(event) => {
                                const newTime = (event.target as HTMLInputElement).value
                                setFieldValue(
                                  `openingTimes[${dayIndex}].slots[${slotIndex}][${timeIndex}]`,
                                  newTime
                                )
                              }}
                            />
                          )
                        })}
                      </div>
                    ))}
                  </div>
                  <div className="sm:col-span-3">
                    <div className="flex flex-row justify-between h-full">
                      <div className="flex flex-col justify-between">
                        <IconButton
                          kind={IconButtonKind.custom}
                          icon={<PlusSignIcon />}
                          additionalCss={`h-fit items-center p-1px ml-3 rounded-md text-gray-700 border border-solid border-gray-400 transition ease-in-out duration-150 ${
                            slots.length > 1 ? 'bg-gray-300 cursor-not-allowed' : 'hover:bg-blue-200 hover:text-blue-700 focus:border-gray-300 active:text-blue-800'
                          }`}
                          onClick={(event) => handleAddSlot(dayIndex, setFieldValue, values.openingTimes)}
                          disabled={slots.length > 1 || closed}
                        />
                        {slots.length > 1 && (
                          <IconButton
                            kind={IconButtonKind.custom}
                            icon={<DeleteIcon />}
                            additionalCss={`h-fit items-center p-1px ml-3 rounded-md text-gray-700 border border-solid border-gray-400 transition ease-in-out duration-150 ${
                              slots.length < 1 ? 'bg-gray-300 cursor-not-allowed' : 'hover:bg-blue-200 hover:text-blue-700 focus:border-gray-300 active:text-blue-800'
                            }`}
                            onClick={() => handleDeleteSlot(dayIndex, setFieldValue, values.openingTimes)}
                            disabled={slots.length < 1 || closed}
                          />
                        )}
                      </div>
                      <Checkbox
                        name={`sendConfirmation[${weekday}]`}
                        label="geschlossen"
                        checked={closed}
                        onChange={() => handleCheckboxChange(dayIndex, setFieldValue, values.openingTimes)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-between p-4 pt-8 pb-6 place-items-stretch">
              <Button kind={ButtonKind.outlinedSecondary} onClick={closeModal}>
                {'Abbrechen'}
              </Button>
              <Button kind={ButtonKind.primary} onClick={handleSubmit}>
                {'Anfrage senden'}
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  )
}

export default PharmacyUpdateModal
