import { PharmacyStatus } from '@aposphaere/core-kit'
import { CloseIcon, IconButton, IconButtonKind, LeftSidebar } from '@aposphaere/ui-components'
import React, { useCallback, useMemo } from 'react'
import 'react-day-picker/lib/style.css'
import { SelectMode } from './SelectMode'
import CrmCalendar from '../CrmCalendar'
import { StepStates, useCalendarPanel } from './CalendarPanelContext'
import { Navigation } from './Navigation'
import ScheduleForm from './ScheduleForm'
import { useNextPossibleDateQuery, usePharmacyQuery } from '../../hooks/graphql'
import { CalendarDay } from '../CrmCalendar/useCalendarData'
import { isSameDay } from 'date-fns'
import { useRoutePlanner } from '../../contexts/routePlannerContext'
import { SidebarStatus } from '../../pages/StartPage/StartPage'

/*
 * PROPS
 */
interface Props {
  pharmacyId: number | undefined
  onClose: () => void
  routeForAppointment: boolean
  setRouteForAppointment: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedPharmacy: React.Dispatch<React.SetStateAction<number | undefined>>
  setShouldUpdateDataItem: React.Dispatch<React.SetStateAction<boolean>>
  sidebarStatus: SidebarStatus
}

const CalenderPanelAppointment = ({
  onClose,
  pharmacyId,
  setRouteForAppointment,
  setSelectedPharmacy,
  sidebarStatus,
  setShouldUpdateDataItem,
}: Props) => {
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  const { setSelectedDay, setShowRouteWithAllPharmacy, selectedDay, isShowRouteWithAllPharmacy, setRouteResponse } = useRoutePlanner()

  const { selectedMode, selectedTime, onTimeslotClick, activeState, setSelectedTime } = useCalendarPanel()
  const { data: pharmacy } = usePharmacyQuery(pharmacyId)
  const { data: nextPossibleDate } = useNextPossibleDateQuery(pharmacyId)

  const onDayClick = useCallback(
    (day: CalendarDay) => {
      if (selectedDay && isShowRouteWithAllPharmacy && isSameDay(selectedDay, day.date)) {
        setSelectedDay(undefined)
        setShowRouteWithAllPharmacy(false)
        setRouteResponse(null)
        setRouteForAppointment(false)
        setSelectedTime(new Date())
        setShouldUpdateDataItem(true)
        return
      }
      if (!selectedDay && !!pharmacyId) {
        setSelectedPharmacy(undefined)
        setSelectedDay(day.date)
      }
      if (!selectedDay) {
        setSelectedDay(day.date)
      }
      if (selectedDay) {
        if (!isSameDay(selectedDay, day.date)) {
          setSelectedDay(day.date)
        } else {
          setShowRouteWithAllPharmacy((prev: boolean) => !prev)
        }
      }
    },
    [selectedDay, isShowRouteWithAllPharmacy, setSelectedDay, setShowRouteWithAllPharmacy, pharmacyId],
  )

  const StepsComponent = useMemo(
    () => ({
      [StepStates.DateSelection]: (
        <>
          <SelectMode />
          <div className={` w-full -mt-2 mb-16 ${selectedMode === 'slot' ? '' : 'opacity-45'}`}>
            <CrmCalendar
              onDayClick={onDayClick}
              onTimeSlotClick={onTimeslotClick}
              disablePastSlots={true}
              selectedTime={selectedTime}
              setRouteForAppointment={setRouteForAppointment}
              sidebarStatus={sidebarStatus}
              setShouldUpdateDataItem={setShouldUpdateDataItem}
            />
          </div>
          <Navigation disabled={!selectedTime} onClose={onClose} step={activeState} setRouteForAppointment={setRouteForAppointment} />
        </>
      ),
      [StepStates.DetailsSection]: <ScheduleForm pharmacyId={pharmacyId} onClose={onClose} setRouteForAppointment={setRouteForAppointment} />,
    }),
    [pharmacyId, onClose, onDayClick, activeState, selectedMode, selectedTime, onTimeslotClick],
  )

  const nextPossibleDateMessage = nextPossibleDate ? `– nächst möglicher Termin ${nextPossibleDate}` : ``
  const isDisabled = pharmacy?.pharmacy_status === PharmacyStatus.Inactive
  const backgroundClass = isDisabled ? 'bg-red-200' : 'bg-green-50'
  const textClass = isDisabled ? 'text-red-700' : 'text-green-600'

  return (
    <LeftSidebar
      //
      //  HEADER
      //
      headerContent={
        <div className="flex w-full flex-wrap font-body bg-white">
          <div className={`flex w-full flex-wrap border-b border-green-300 ${backgroundClass} py-2.5`}>
            <div className="flex w-full h-16 2xl:min-h-20 items-center 2xl:pl-4">
              <div className="flex w-10/12 pl-2">
                <h1 className={`text-base 2xl:text-lg ${textClass} leading-6 font-medium `}>
                  <span>{`Neuer Termin:`}</span>
                  <br />
                  <span>
                    {pharmacy?.name || ''} {nextPossibleDateMessage}
                  </span>
                </h1>
              </div>
              <div className="flex w-2/12 justify-end pr-2 text-blue-800 items-center">
                <IconButton kind={IconButtonKind.default} icon={<CloseIcon />} onClick={onClose} />
              </div>
            </div>
          </div>
        </div>
      }
      showMax
    >
      {StepsComponent[activeState]}
    </LeftSidebar>
  )
}

export default CalenderPanelAppointment
