import { FinishedPresentationsResponse, parseUTC, Pharmacy } from '@aposphaere/core-kit'
import { SectionCard } from '@aposphaere/ui-components'
import React, { useState, useMemo } from 'react'
import { compareDesc, differenceInMinutes } from 'date-fns'
import { useCrmContext } from '../../contexts/crmContext'
export interface SlotCardProps {
  showSpecialButton: boolean
  pharmacy: Pharmacy
}

function convertTime(minutes: number): string {
  if (minutes < 60) {
    return `${minutes} minutes`
  } else {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60
    return `${hours} hour${hours > 1 ? 's' : ''} ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`
  }
}

const groupByProject = (presentations: FinishedPresentationsResponse[]) => {
  const initialResult: { [key: string]: FinishedPresentationsResponse[] } = {}
  return presentations.reduce((acc, curr) => {
    const projectId = curr.project.id
    if (!acc[projectId]) {
      acc[projectId] = []
    }
    acc[projectId].push(curr)
    acc[projectId].sort((a, b) => compareDesc(parseUTC(a.starttime), parseUTC(b.starttime)))
    return acc
  }, initialResult)
}

const SlotCard: React.FunctionComponent<SlotCardProps> = ({ pharmacy }) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const { isUserCallcenter } = useCrmContext()
  const listOfGroupedPresentations = useMemo(() => {
    const finishedPresentations = pharmacy?.finished_presentations ?? []
    const groupedPresentations = groupByProject(finishedPresentations)
    return Object.values(groupedPresentations) ?? []
  }, [pharmacy])

  interface SlotItem {
    numberOfProjectPasses: number
    projectName: string
    ipadCount: number
    lengthOfTraining: string
    dateCreated: Date
    isFR: boolean
  }

  const slotItems: SlotItem[] = useMemo(
    () =>
      listOfGroupedPresentations
        .map((groupedPresentation) => {
          const numberOfProjectPasses = groupedPresentation.length
          const projectName = groupedPresentation[0].project.name ?? ''
          const ipadCount = groupedPresentation.reduce((acc, curr) => acc + curr.device_count, 0)
          const lengthOfTraining = groupedPresentation.reduce((acc, curr) => {
            const currentTotalTime = parseInt(curr.time_spent, 10) / 60
            return acc + currentTotalTime
          }, 0)
          const roundedLengthOfTraining = Math.round(lengthOfTraining)
          const IsFR = groupedPresentation[0].fr_trainer_id
          return {
            numberOfProjectPasses,
            projectName,
            ipadCount,
            lengthOfTraining: convertTime(roundedLengthOfTraining),
            dateCreated: parseUTC(groupedPresentation[0].starttime),
            isFR: !!IsFR,
          }
        })
        .sort((a, b) => compareDesc(a.dateCreated, b.dateCreated)),
    [listOfGroupedPresentations],
  )

  return (
    <SectionCard isExpanded={expanded} setExpanded={setExpanded} title="Slot" showAddButton={false} showSpecialButton={false} showAllEntries={true}>
      <div className="px-2 2xl:px-4">
        {slotItems.length === 0 ? (
          <p className="w-full py-2 text-base text-gray-600 text-center">{'Keine Einträge vorhanden'}</p>
        ) : (
          <>
            {
            /*isUserCallcenter ? (
              <li className="w-full px-2 2xl:px-4 border-b border-gray-400 last:border-0">
                <div className="grid grid-cols-7 xl:grid-cols-6 gap-2 w-full py-2" key={slotItems[slotItems.length - 1].projectName}>
                  <div className="flex col-span-2 min-w-130px">
                    <div className="text-base leading-6 font-medium whitespace-normal">
                      <span className="font-medium">{`${slotItems[slotItems.length - 1].numberOfProjectPasses} X ${
                        slotItems[slotItems.length - 1].projectName
                      }`}</span>
                      <br />
                      <span className="font-medium ">{`${slotItems[slotItems.length - 1].ipadCount} iPad${
                        slotItems[slotItems.length - 1].ipadCount > 1 ? 's' : ''
                      }`}</span>
                    </div>
                  </div>
                  <div className="col-span-1 xl:hidden" />
                  <div className={`flex col-span-3 items-center ml-16`}>
                    <div className="text-base leading-6 font-medium">
                      <div className="font-normal">
                        <p className="">{slotItems[slotItems.length - 1].lengthOfTraining}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ) : */
            (
              <li className="w-full px-2 2xl:px-4 border-b border-gray-400 last:border-0">
                 {slotItems?.map(({ numberOfProjectPasses, projectName, lengthOfTraining, ipadCount, isFR }) => (
                  <div className="grid grid-cols-7 xl:grid-cols-6 gap-2 w-full py-2" key={projectName}>
                    <div className="flex col-span-2 min-w-130px">
                      <div className="text-base leading-6 font-medium whitespace-normal">
                        <span className="font-medium">{`${numberOfProjectPasses} X ${projectName}`}</span>
                        <br />
                        <span className="font-medium ">{`${ipadCount} iPad(s)${ipadCount > 1 ? 's' : ''}`}</span>
                      </div>
                    </div>
                    <div className="col-span-1 xl:hidden" />
                    <div className={`flex col-span-3 items-center ml-16`}>
                      <div className="text-base leading-6 font-medium">
                        <div className="font-normal">
                          <p className="">{lengthOfTraining}</p>
                          {isFR && <p className="font-medium ">FR</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </li>
            )}
          </>
        )}
      </div>
    </SectionCard>
  )
}

export default SlotCard
