import { Pharmacy, PharmacyStatus, requestUpdatePharmacy } from '@aposphaere/core-kit'
import { Button, ButtonKind, InputLabel, InputMessage, InputMessageKind, Modal, ModalKind, TextInput, toast } from '@aposphaere/ui-components'
import { Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'

import { useModal } from '../../contexts/modalContext'
import { usePharmacyQuery, usePharmaciesSearchResults } from '../../hooks/graphql'
import { useAuthenticatedMutation } from '../../hooks/useAuthenticatedMutation'
import ParentSearchPanel from './ParentSearchPanel'

interface PharmacyFormValues extends Partial<Pharmacy> {
  address_name?: string
  zipcode?: string
  city?: string
  reason?: string
  pharmacyStatus?: PharmacyStatus
  other_reason?: string
  fax?: string
  parent_okid?: string
}

interface Props {
  id: number
}

type PharmacySearchResults = {
  id: string
  name: string
  okid: string
}

const PharmacyUpdateModal: React.FC<Props> = ({ id }) => {
  const { data: currentlyActivePharmacy, refetch: refetchPhamracy } = usePharmacyQuery(id)
  const { closeModal } = useModal()
  const [parentPharmacySearch, setParentPharmacySearch] = useState<string>('')
  const [parentOkid, setParentOkid] = useState<string>()
  const { data: pharmaciesBySearch } = usePharmaciesSearchResults({ search: parentPharmacySearch })

  const updatePharmacyMutation = useAuthenticatedMutation(requestUpdatePharmacy)
  const getMutationWithVariables = (values: PharmacyFormValues) => {
    const isActive = values.pharmacyStatus === 'active' ? true : false
    const variables = {
      okid: currentlyActivePharmacy?.okid,
      name: values.name,
      phone: values.phone,
      email: values.email,
      fax: values.fax,
      url: values.url,
      street: values.address_name,
      city: values.city,
      zipcode: values.zipcode,
      parent_okid: parentOkid || '',
      active: isActive,
    }
    const mutation = updatePharmacyMutation
    return { mutation, variables }
  }

  const initialFormValues: PharmacyFormValues = {
    name: currentlyActivePharmacy?.name,
    address_name: currentlyActivePharmacy?.address?.address_name,
    zipcode: currentlyActivePharmacy?.address?.zipcode,
    city: currentlyActivePharmacy?.address?.city,
    contact_person: currentlyActivePharmacy?.contact_person,
    phone: currentlyActivePharmacy?.phone?.replace(' ', ''),
    email: currentlyActivePharmacy?.email,
    url: currentlyActivePharmacy?.url,
    pharmacyStatus: currentlyActivePharmacy?.pharmacy_status,
    fax: currentlyActivePharmacy?.fax,
    parent_okid: currentlyActivePharmacy?.parent?.okid,
  }

  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
  // const PharmacyValidationScheme = Yup.object().shape({
  //   name: Yup.string().min(3, 'Must be more then 3 symbols').max(35, 'Must be less then 35 symbols').required(),
  //   address_name: Yup.string().min(3, 'Must be more then 3 symbols').max(35, 'Must be less then 35 symbols').required(),
  //   zipcode: Yup.string().matches(/^\d+$/, 'Must be only digits').required().min(5, 'Must be exactly 5 digits').max(5, 'Must be exactly 5 digits'),
  //   city: Yup.string().min(3, 'Must be more then 3 symbols').max(25, 'Must be less then 25 symbols').required(),
  //   contact_person: Yup.string().min(3, 'Must be more then 3 symbols').max(25, 'Must be less then 25 symbols').required(),
  //   phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required(),
  //   email: Yup.string().email().required(),
  //   url: Yup.string().url().nullable(),
  // })

  const onFormSubmit = async (values: PharmacyFormValues) => {
    const { mutation, variables } = getMutationWithVariables(values)
    const PharmacyResponse = await mutation(variables)
    if (PharmacyResponse.errors !== undefined) {
      alert(PharmacyResponse.errors)
      return
    }
    try {
      toast.show({
        headline: 'Anfrage erfolgreich gesendet',
        type: 'success',
      })
    } catch {
      toast.show({
        headline: 'Anfrage kann nicht gesendet werden',
        type: 'error',
      })
    }
    await refetchPhamracy()
    closeModal()
  }

  const onSearchSuggestionClicked = (pharmacy: PharmacySearchResults) => {
    setParentOkid(pharmacy.okid)
    setParentPharmacySearch(pharmacy.okid)
  }

  return (
    <Modal kind={ModalKind.ms} title={'Apotheke bearbeiten'} onClose={closeModal} onBack={() => null}>
      <Formik key="pharmacy-updated-form" initialValues={initialFormValues} onSubmit={onFormSubmit}>
        {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
          <div className="flex flex-wrap w-full">
            <div className="flex flex-wrap w-full">
              <div className="w-1/2 p-4">
                <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <InputLabel>{'Standortname:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('name', event.currentTarget.value)}
                        type="text"
                        value={values.name || ''}
                      />
                      {errors.name && touched.name ? <InputMessage kind={InputMessageKind.error}>{errors.name}</InputMessage> : null}
                    </div>
                  </div>
                  <div className="sm:col-span-6">
                    <InputLabel>{'Strasse:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('address_name', event.currentTarget.value)}
                        type="text"
                        value={values.address_name || ''}
                      />
                      {errors.address_name && touched.address_name ? (
                        <InputMessage kind={InputMessageKind.error}>{errors.address_name}</InputMessage>
                      ) : null}
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <InputLabel>{'PLZ:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('zipcode', event.currentTarget.value)}
                        type="text"
                        value={values.zipcode || ''}
                      />
                      {errors.zipcode && touched.zipcode ? <InputMessage kind={InputMessageKind.error}>{errors.zipcode}</InputMessage> : null}
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <InputLabel>{'Ort:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('city', event.currentTarget.value)}
                        type="text"
                        value={values.city || ''}
                      />
                      {errors.city && touched.city ? <InputMessage kind={InputMessageKind.error}>{errors.city}</InputMessage> : null}
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="w-1/2 p-4">
                <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <InputLabel>{'Parent OKID:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setParentPharmacySearch(event.currentTarget.value)}
                        type="text"
                        value={parentPharmacySearch}
                      />
                      <div className="max-h-250px overflow-y-auto">
                        <ParentSearchPanel
                          onSearchSuggestionClicked={onSearchSuggestionClicked}
                          showSearchSuggestions={parentPharmacySearch !== ''}
                          pharmacies={pharmaciesBySearch || []}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <InputLabel>{'Telefon:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('phone', event.currentTarget.value.replace(' ', ''))}
                        type="text"
                        value={values.phone || ''}
                      />
                      {errors.phone && touched.phone ? <InputMessage kind={InputMessageKind.error}>{errors.phone}</InputMessage> : null}
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <InputLabel>{'E - Mail - Adresse:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('email', event.currentTarget.value)}
                        type="text"
                        value={values.email || ''}
                      />
                      {errors.email && touched.email ? <InputMessage kind={InputMessageKind.error}>{errors.email}</InputMessage> : null}
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <InputLabel>{'Apothekenstatus:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <select
                        defaultValue={values.pharmacyStatus}
                        onChange={(event: React.FormEvent<HTMLSelectElement>) => {
                          setFieldValue('pharmacyStatus', event.currentTarget.value)
                        }}
                        className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400"
                      >
                        <option value={PharmacyStatus.Active}>Aktiv</option>
                        <option value={PharmacyStatus.Inactive}>Inaktiv</option>
                      </select>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <InputLabel>{'Fax:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('fax', event.currentTarget.value)}
                        type="text"
                        value={values.fax || ''}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-6">
                    <InputLabel>{'Website:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('url', event.currentTarget.value)}
                        type="text"
                        value={values.url || ''}
                      />
                      {errors.url && touched.url ? <InputMessage kind={InputMessageKind.error}>{errors.url}</InputMessage> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-between p-4 pt-8 pb-6 place-items-stretch">
              <Button kind={ButtonKind.outlinedSecondary} onClick={closeModal}>
                {'Abbrechen'}
              </Button>
              <Button kind={ButtonKind.primary} onClick={handleSubmit} disabled={isSubmitting}>
                {'Anfrage senden'}
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  )
}

export default PharmacyUpdateModal