import React from 'react'
import { Route, Switch } from 'react-router-dom'
import CrmProvider from '../contexts/crmContext'
import { RoutePlannerProvider } from '../contexts/routePlannerContext'
import MapProvider from '../contexts/mapContext'
import { ModalProvider } from '../contexts/modalContext'
import MainLayout from '../layouts/MainLayout'
import StartPage from './StartPage'
import Spinner from '../components/loader.gif'
import DashboardPage from './DashboardPage'
import { DASHBOARD_PAGE_ROUTE } from '../routes'
import { NavWrapper } from '@aposphaere/ui-components'
import DashboardProvider from '../contexts/dashboardContext'
import { Links } from '../components/Links'

const LoginPage = React.lazy(() => import('./LoginPage'))
const AddressesPage = React.lazy(() => import('./AddressPage'))
const ReminderItemsPage = React.lazy(() => import('./ReminderItemsPage'))

const Pages: React.FC = () => (
  <Switch>
    <React.Suspense fallback={<SpinnerComponent />}>
      <Route path="/login" exact component={LoginPage} />
      <Providers>
        <MainLayout>
          <React.Suspense fallback={<SpinnerComponent />}>
            <Route path="/" exact component={StartPage} />
            <Route path="/reminders" exact component={ReminderItemsPage} />
            <Route path="/addresses" exact component={AddressesPage} />
            <Route path="/links" exact component={Links} />
            <DashboardProvider>
              <Route path={DASHBOARD_PAGE_ROUTE} exact component={DashboardPage} />
            </DashboardProvider>
          </React.Suspense>
        </MainLayout>
      </Providers>
    </React.Suspense>
  </Switch>
)

const Providers: React.FC = ({ children }) => (
  <CrmProvider>
    <NavWrapper>
      <MapProvider>
        <RoutePlannerProvider>
          <ModalProvider>{children}</ModalProvider>
        </RoutePlannerProvider>
      </MapProvider>
    </NavWrapper>
  </CrmProvider>
)

const SpinnerComponent = () => (
  <div className="h-screen w-screen bg-white z-20 absolute flex items-center justify-center">
    <div className="flex items-center justify-center flex-wrap max-w-48">
      <img className="flex w-24" src={Spinner} alt="" />
      <br />
      <div className="w-full text-center mx-auto flex justify-center font-body mt-6 text-base text-blue-400 leading-5">{'Daten werden geladen.'}</div>
    </div>
  </div>
)

export default Pages
