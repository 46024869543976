/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Appointment, Pharmacy, PharmacyStatus, AppointmentType, parseUTC } from '@aposphaere/core-kit'
import { StatusWithCancelledProp } from '../../hooks/graphql'
import customMarker, { CustomMarkerColors, getAppointmentRanking } from './Markers/customMarker'
import { ComputedStateType } from './types'
import { isFuture } from 'date-fns'
import { REMOTE_APPOINTMENT_TYPE_FRAGMENT } from '../../constants'

const shadeByState = (state: string) => {
  switch (state) {
    case ComputedStateType.EXECUTED_APPOINTMENT:
      return true
    default:
      return false
  }
}

const colorByState = (state: string) => {
  switch (state) {
    case ComputedStateType.NOT_SERVED:
      return CustomMarkerColors.gray
    case ComputedStateType.EXECUTED_APPOINTMENT:
      return CustomMarkerColors.green
    case ComputedStateType.PLANNED_APPOINTMENT:
      return CustomMarkerColors.green
    case ComputedStateType.FOLLOW_UP:
      return CustomMarkerColors.yellow
    case ComputedStateType.REMOTE_APPOINTMENT:
      return CustomMarkerColors.purple
    case ComputedStateType.EXECUTED_VISIT:
      return CustomMarkerColors.orange
    case ComputedStateType.PLANNED_VISIT:
      return CustomMarkerColors.black
    case ComputedStateType.DEACTIVATED:
      return CustomMarkerColors.red
    default:
      return CustomMarkerColors.gray
  }
}

const getIcon = (
  pharmacy: Pharmacy,
  isVisit: boolean,
  text?: string,
  sortedAppointmentsToday?: Appointment[] | null,
  sortedVisitsToday?: Appointment[] | null,
) => {
  const pharmacyAppointments = sortedAppointmentsToday?.filter((appointment) => (appointment.pharmacy?.id || '0') === pharmacy.id) ?? []
  const pharmacyVisits = sortedVisitsToday?.filter((appointment) => (appointment.pharmacy?.id || '0') === pharmacy.id) ?? []
  const futureAppointments = pharmacyAppointments?.filter((appointment) => appointment?.date && isFuture(parseUTC(appointment?.date)))
  const VISIT_APPOINTMENT_TYPE_FRAGMENT = 'besuch'
  const TRANING_APPOINTMENT_TYPE_FRAGMENT = 'schulung'
  const futureVisit = futureAppointments?.some(
    (appointment) => appointment?.appointmentType?.label?.toLowerCase() === VISIT_APPOINTMENT_TYPE_FRAGMENT,
  )

  const hasVisitAtAll = pharmacyAppointments?.some(
    (appointment) => appointment?.appointmentType?.label?.toLowerCase() === VISIT_APPOINTMENT_TYPE_FRAGMENT,
  )
  const hasTrainingAtAll = pharmacyAppointments?.some(
    (appointment) => appointment?.appointmentType?.label?.toLowerCase() === TRANING_APPOINTMENT_TYPE_FRAGMENT,
  )
  const futureTraingAppoinments = futureAppointments?.some(
    (appointment) => appointment?.appointmentType?.label?.toLowerCase() === TRANING_APPOINTMENT_TYPE_FRAGMENT,
  )

  const isAppointmentVisit = pharmacyVisits?.some(
    (appointment) => appointment?.appointmentType?.label?.toLowerCase() === VISIT_APPOINTMENT_TYPE_FRAGMENT,
  )

  const determineFutureVisit = (futureAppVisit: boolean, futureAppTraining: boolean) => {
    if (futureAppVisit && hasTrainingAtAll) {
      return false
    }

    if (hasVisitAtAll && !hasTrainingAtAll) {
      return true
    }

    if (futureAppVisit && !futureAppTraining && hasTrainingAtAll) {
      return false
    }
    return false
  }

  const hasRemoteAppointment = pharmacyAppointments?.some((appointment) => isRemoteAppointment(appointment))

  const shouldShowRemoteAppointment = hasRemoteAppointment && !futureTraingAppoinments

  const isFutureVisit = determineFutureVisit(!!futureVisit, !!futureTraingAppoinments)
  const isDeactivated = pharmacy.pharmacy_status === PharmacyStatus.Inactive
  const letter = text ? text : pharmacy.computed_label ? pharmacy.computed_label : ''
  if (isDeactivated) {
    return { letter, color: CustomMarkerColors.red }
  }

  if ((hasTrainingAtAll || isFutureVisit) && futureTraingAppoinments) {
    return { letter, color: CustomMarkerColors.green, shaded: false }
  }

  if (hasTrainingAtAll && !futureTraingAppoinments) {
    return { letter, color: CustomMarkerColors.green, shaded: true }
  }

  if (isVisit && isFutureVisit) {
    return { letter, color: CustomMarkerColors.orange }
  }

  if (shouldShowRemoteAppointment) {
    return { letter, color: CustomMarkerColors.purple }
  }

  const finalComputedState = isAppointmentVisit ? ComputedStateType.EXECUTED_VISIT : pharmacy?.computed_state

  const result = { color: colorByState(finalComputedState!), letter, shaded: shadeByState(finalComputedState!) }
  if (pharmacy.tasks?.length) {
    return {
      ...result,
      color: result.color === CustomMarkerColors.green ? result.color : CustomMarkerColors.yellow,
      shaded: false,
    }
  }
  return result
}

interface DetermineIsVisitOptions {
  statuses: StatusWithCancelledProp[]
  appointmentTypes: AppointmentType[]
}

export const determineIsVisit = (appointments: Appointment[] | null, { statuses, appointmentTypes }: DetermineIsVisitOptions): boolean => {
  if (appointments) {
    const cancelledStatusIds = statuses.filter((status) => status.isCancelled).map(({ id }) => String(id))

    const VISIT_APPOINTMENT_TYPE_FRAGMENT = 'besuch'

    const activeAppointments = appointments.filter((appointment) => !cancelledStatusIds.includes(String(appointment.status_id)))

    const visitAppointmentType = appointmentTypes?.find((type) => type.label?.toLowerCase()?.includes(VISIT_APPOINTMENT_TYPE_FRAGMENT))
    if (!visitAppointmentType) {
      return false
    }

    const pharmacyVisits = activeAppointments?.filter((appointment) => {
      const appointTypeId = appointment.appointmentType?.id || null
      return appointTypeId && appointTypeId === visitAppointmentType?.id
    })

    const pharmacyHasVisits = !!pharmacyVisits?.length
    const pharmacyHasOtherAppointments = activeAppointments.length > pharmacyVisits?.length

    return pharmacyHasVisits && !pharmacyHasOtherAppointments
  }
  return false
}

export const isRemoteAppointment = (appointment: Appointment) => {
  return appointment.appointmentType?.label?.toLowerCase() === REMOTE_APPOINTMENT_TYPE_FRAGMENT
}

export const determineMapIcon = ({
  pharmacy,
  sortedAppointmentsToday,
  sortedVisitsToday,
  isVisit,
  selectedDay,
}: {
  pharmacy: Pharmacy
  sortedAppointmentsToday: Appointment[] | null
  selectedDay: Date | undefined
  isVisit: boolean
  sortedVisitsToday?: Appointment[] | null
  allAppointmentsCancelled?: boolean
}) => {
  const pharmacyAppointments = sortedAppointmentsToday?.filter(
    (appointment) => (appointment.pharmacy?.id || '0') === pharmacy.id && appointment.date && isFuture(new Date(appointment.date)),
  )
  let rank = ''

  if (pharmacyAppointments?.length === 1) {
    rank = getAppointmentRanking(parseUTC(pharmacyAppointments[0].date!)) ?? ''
  } else if (pharmacyAppointments && pharmacyAppointments?.length > 1) {
    rank = pharmacyAppointments.map((appointment) => getAppointmentRanking(parseUTC(appointment.date!))).join('/')
  }

  const iconData = getIcon(pharmacy, isVisit, selectedDay ? rank : undefined, sortedAppointmentsToday, sortedVisitsToday)
  return customMarker({ ...iconData })
}
