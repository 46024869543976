import { Appointment, FinishedPresentationsResponse, parseUTC, Pharmacy } from '@aposphaere/core-kit'
import { SectionCard } from '@aposphaere/ui-components'
import React, { useState, useMemo, useCallback } from 'react'
import { format, compareDesc } from 'date-fns'
import { useCancelledStatusesIds, useCompletedStatusId } from '../../hooks/graphql'
import { useCrmContext } from '../../contexts/crmContext'

export interface HistoryCardProps {
  showSpecialButton: boolean
  pharmacy: Pharmacy
  appointments: Appointment[]
}

const groupByProject = (presentations: AllowedPresentations[]) => {
  const initialResult: { [key: string]: AllowedPresentations[] } = {}
  return presentations.reduce((acc, curr) => {
    const trainerId = curr?.trainer?.id ?? ''
    if (!acc[trainerId]) {
      acc[trainerId] = []
    }
    acc[trainerId].push(curr)
    acc[trainerId].sort((a, b) => compareDesc(parseUTC(a.starttime), parseUTC(b.starttime)))
    return acc
  }, initialResult)
}

type AllowedPresentations = FinishedPresentationsResponse & {
  appointmentDate: string
}

const VISIT_APPOINTMENT_TYPE_FRAGMENT = 'Besuch'

const HistoryCard: React.FunctionComponent<HistoryCardProps> = ({ pharmacy, appointments }) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const { isUserCallcenter } = useCrmContext()
  const cancelledStatusIds = useCancelledStatusesIds()
  const cancelledAppointments = appointments.filter(
    (appointment) => cancelledStatusIds.includes(appointment?.status?.id ?? '') && appointment?.pharmacy?.id === pharmacy.id,
  )
  const visitAppointments = appointments.filter(
    (appointment) =>
      appointment?.appointmentType?.label === VISIT_APPOINTMENT_TYPE_FRAGMENT &&
      appointment?.pharmacy?.id === pharmacy.id &&
      !cancelledStatusIds.includes(appointment?.status?.id ?? ''),
  )
  const completedStatusIds = useCompletedStatusId()
  const trainingAppointments = appointments.filter((appointment) => completedStatusIds.includes(appointment?.status?.id ?? ''))

  const getAllowedPresentations = useCallback(
    (targetAppointments: Appointment[]) =>
      targetAppointments
        .filter((appointment) => appointment.pharmacy?.id === pharmacy?.id)
        .map(
          ({ finished_presentations, date }) =>
            (finished_presentations ?? []).map((presentation) => ({ ...presentation, appointmentDate: date || '' })) ?? [],
        )
        .flat(),
    [pharmacy],
  )

  const allowedTrainingPresentations: AllowedPresentations[] = useMemo(() => getAllowedPresentations(trainingAppointments), [
    trainingAppointments,
    getAllowedPresentations,
  ])

  const listOfGroupedTrainingPresentations: AllowedPresentations[][] = useMemo(
    () => Object.values(groupByProject(allowedTrainingPresentations)) ?? [],
    [allowedTrainingPresentations],
  )

  interface HistoryItem {
    ipadCount: number
    trainer: string
    appointmentDate: string
    projects: string[] | string
    status?: string | string[]
  }

  const getHistoryItems = (presentations: AllowedPresentations[][]) =>
    presentations.map((presentation) => {
      const ipadCount = presentation.reduce((acc, curr) => acc + curr.number_of_participants, 0)
      const trainer = presentation[0].trainer.name
      const appointmentDate = format(parseUTC(presentation[0].appointmentDate), 'dd.MM.yyyy')
      const projects = presentation
        .reduce((accumulator: string[], curr) => {
          const projectName = curr.project?.name ?? ''
          if (!accumulator.includes(projectName)) {
            accumulator.push(projectName)
          }
          return accumulator
        }, [])
        .join(', ')

      return {
        ipadCount,
        trainer,
        appointmentDate,
        projects: projects,
      }
    })

  const getVisitAndTrainingItems = (apppoinments: Appointment[]) =>
    apppoinments.map((appointment) => {
      const ipadCount = 0
      const trainer = appointment?.creator?.name
      const appointmentDate = format(parseUTC(appointment.date!), 'dd.MM.yyyy')
      return {
        ipadCount,
        trainer,
        appointmentDate,
      }
    })

  const historyVisitItems: Partial<HistoryItem>[] = useMemo(() => getVisitAndTrainingItems(visitAppointments), [visitAppointments])

  const historyCancelledItems: Partial<HistoryItem>[] = useMemo(() => getVisitAndTrainingItems(cancelledAppointments), [cancelledAppointments])

  const historyTrainingItems: HistoryItem[] = useMemo(() => getHistoryItems(listOfGroupedTrainingPresentations), [listOfGroupedTrainingPresentations])

  const allHistoryItems = [...historyTrainingItems, ...historyVisitItems, ...historyCancelledItems]

  let lastActivity: Partial<HistoryItem> = allHistoryItems.reduce((prev, current) => {
    const prevDate = prev.appointmentDate ? new Date(prev.appointmentDate) : null
    const currentDate = current.appointmentDate ? new Date(current.appointmentDate) : null

    if (prevDate && currentDate) {
      return prevDate > currentDate ? prev : current
    } else if (prevDate) {
      return prev
    } else if (currentDate) {
      return current
    }
    return prev
  }, {})

  if (lastActivity) {
    if (historyTrainingItems.includes(lastActivity as HistoryItem)) {
      lastActivity.status = lastActivity.projects
    } else if (historyVisitItems.includes(lastActivity as HistoryItem)) {
      lastActivity.status = 'Besuch ohne Schulung'
    } else if (historyCancelledItems.includes(lastActivity as HistoryItem)) {
      lastActivity.status = 'abgesagt'
    }
  }

  const generateGridItem = (historyItem: Partial<HistoryItem>, text: string | string[]) => {
    const { appointmentDate, ipadCount, trainer } = historyItem

    return (
      <div className="grid grid-cols-7 xl:grid-cols-6 gap-2 w-full py-2" key={`${trainer}_${appointmentDate}_${ipadCount}`}>
        <div className="flex col-span-2 min-w-130px">
          <div className="text-base leading-6 font-medium whitespace-normal">
            <span className="font-medium">{appointmentDate}</span>
            <br />
            <span className="font-medium ">{ipadCount} Tln</span>
          </div>
        </div>
        <div className="col-span-1 xl:hidden" />
        <div className={`flex col-span-3 items-center ml-16`}>
          <div className="text-base leading-6 font-medium">
            <span className="font-normal">{text}</span>
            <br />
            <div className="font-medium">
              <p className="">{trainer}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <SectionCard
      isExpanded={expanded}
      setExpanded={setExpanded}
      title="Historie"
      showAddButton={false}
      showSpecialButton={false}
      showAllEntries={true}
    >
      <div className="px-2 2xl:px-4 ">
        {historyTrainingItems.length === 0 && historyCancelledItems.length === 0 && historyVisitItems.length === 0 ? (
          <p className="w-full py-2 text-base text-gray-600 text-center">{'Keine Einträge vorhanden'}</p>
        ) : 
        /*isUserCallcenter ? (
          <li className="w-full px-2 2xl:px-4 border-b border-gray-400 last:border-0">
            <div className="grid grid-cols-7 xl:grid-cols-6 gap-2 w-full py-2">
              <div className="flex col-span-2 min-w-130px">
                <div className="text-base leading-6 font-medium whitespace-normal">
                  <span className="font-medium">{lastActivity?.appointmentDate?.toString()}</span>
                  <br />
                  <span className="font-medium ">{lastActivity?.ipadCount} Tln</span>
                </div>
              </div>
              <div className="col-span-1 xl:hidden" />
              <div className={`flex col-span-3 items-center ml-16`}>
                <div className="text-base leading-6 font-medium">
                  <span className="font-normal">{lastActivity?.status}</span>
                  <br />
                  <div className="font-medium">
                    <p className="">{lastActivity?.trainer}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ) : */(
          <li className="w-full px-2 2xl:px-4 border-b border-gray-400 last:border-0">
            {historyTrainingItems?.map(({ appointmentDate, ipadCount, trainer, projects }) =>
              generateGridItem({ appointmentDate, ipadCount, trainer }, projects),
            )}
            {historyVisitItems?.map(({ appointmentDate, ipadCount, trainer }) =>
              generateGridItem({ appointmentDate, ipadCount, trainer }, 'Besuch ohne Schulung'),
            )}
            {historyCancelledItems?.map(({ appointmentDate, ipadCount, trainer }) =>
              generateGridItem({ appointmentDate, ipadCount, trainer }, 'abgesagt'),
            )}
          </li>
        )}
      </div>
    </SectionCard>
  )
}

export default HistoryCard
