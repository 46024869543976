import { Appointment, ILeg, IRouteResponse, Pharmacy, svgToDataUri, UserAddress } from '@aposphaere/core-kit'
import { DirectionsRenderer, DirectionsService, Polyline } from '@react-google-maps/api'
import React, { memo, useState, useEffect } from 'react'
import { useRoutePlanner } from '../../contexts/routePlannerContext'
import { arrayOfColors } from './constants'
import HomeMarker from './Markers/HomeMarker'
import { MapMarker } from './Markers/MapMarker'
import { useCrmContext } from '../../contexts/crmContext'
import { useCalendarPanel } from '../CalendarPanelAppointment/CalendarPanelContext'
import { parseUTC } from '@aposphaere/core-kit'
import { parse, format } from 'date-fns'

const BERLIN_ADDRESS: UserAddress = {
  address_name: 'Home',
  city: 'Berlin',
  zipcode: '246045',
  latitude: 49.23923998075661,
  longitude: 11.376917311914081,
}

interface ICustomDirectionsRenderer {
  sortedAppointmentsToday?: Appointment[]
  activePharmacy?: Pharmacy
}

interface IDestination {
  lat: number
  lng: number
  date?: Date | string | undefined
}

const CustomDirectionsRenderer: React.FC<ICustomDirectionsRenderer> = ({ sortedAppointmentsToday, activePharmacy }) => {
  const { setRouteResponse, routeResponse } = useRoutePlanner()
  const { activeCluster } = useCrmContext()
  const { selectedTime } = useCalendarPanel()
  const originalDate = new Date(selectedTime)
  const parsedDate = format(originalDate, 'yyyy-MM-dd HH:mm:ss')

  const origin = activeCluster?.agent?.address ?? BERLIN_ADDRESS

  const directionsCallback = (res: IRouteResponse) => {
    if (res !== null) {
      if (res.status === 'OK') {
        setRouteResponse(res)
      } else {
        console.error('response: ', res)
      }
    }
  }

  const [destinations, setDestinations] = useState<IDestination[]>([])

  useEffect(() => {
    let updatedDestinations: IDestination[] = []

    if (activePharmacy && !sortedAppointmentsToday?.length) {
      const newDestination = {
        lat: activePharmacy?.address?.latitude || 0,
        lng: activePharmacy?.address?.longitude || 0,
      }
      updatedDestinations = [newDestination]
    } else if (sortedAppointmentsToday && !activePharmacy) {
      const appointmentDestinations = sortedAppointmentsToday.map((appointment) => ({
        lat: appointment.pharmacy?.address?.latitude || 0,
        lng: appointment.pharmacy?.address?.longitude || 0,
      }))
      updatedDestinations = appointmentDestinations
    } else if (sortedAppointmentsToday && activePharmacy) {
      const appointmentDestinations = sortedAppointmentsToday.map((appointment) => ({
        lat: appointment.pharmacy?.address?.latitude || 0,
        lng: appointment.pharmacy?.address?.longitude || 0,
        date: appointment.date,
      }))

      const activePharmacyDestination = {
        lat: activePharmacy?.address?.latitude || 0,
        lng: activePharmacy?.address?.longitude || 0,
        date: parsedDate,
      }

      updatedDestinations = [...updatedDestinations, activePharmacyDestination, ...appointmentDestinations]

      updatedDestinations.sort(
        (a, b) =>
          (a.date ? (typeof a.date === 'string' ? parseUTC(a.date).getTime() : a.date.getTime()) : selectedTime.getTime()) -
          (b.date ? (typeof b.date === 'string' ? parseUTC(b.date).getTime() : b.date.getTime()) : selectedTime.getTime()),
      )
    }

    setDestinations(updatedDestinations)
  }, [activePharmacy, sortedAppointmentsToday])

  const waypoints = destinations.map((destination) => ({ location: destination }))
  return (
    <>
      {destinations.length !== 0 && routeResponse === null && origin && (
        <DirectionsService
          options={{
            destination: { lng: origin.longitude, lat: origin.latitude },
            origin: { lng: origin.longitude, lat: origin.latitude },
            travelMode: 'DRIVING',
            waypoints,
          }}
          callback={directionsCallback}
        />
      )}

      {routeResponse !== null && (
        <>
          <DirectionsRenderer
            options={{
              directions: routeResponse,
              suppressMarkers: true,
              suppressInfoWindows: true,
              suppressPolylines: true,
            }}
          />
          {routeResponse.routes[0].legs
            .filter((leg: ILeg) => !!leg.duration.value)
            .reverse()
            .map((leg, index, arr) =>
              leg.steps.map((step) => (
                <Polyline
                  key={step.end_point.lat()}
                  path={step.path.map((dot) => ({ lat: dot.lat(), lng: dot.lng() }))}
                  visible={true}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  options={{ strokeColor: arrayOfColors[arr.length - 1 - index] || '#C6A8FF', strokeWeight: 6 }}
                />
              )),
            )}
          <MapMarker
            key={'home'}
            id={(origin.longitude || 0) + (origin.latitude || 0)}
            long={origin.longitude || 0}
            lat={origin.latitude || 0}
            icon={svgToDataUri(HomeMarker())}
            onClick={() => null}
          />
        </>
      )}
    </>
  )
}

export default memo(CustomDirectionsRenderer)
