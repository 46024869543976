import { Appointment, Offtime, Project } from '@aposphaere/core-kit'
import { DayItem, PlusSignIcon, WeekItem } from '@aposphaere/ui-components'
import { isPast, isSameDay } from 'date-fns'
import React, { useCallback, useMemo } from 'react'
import { useCrmContext } from '../../contexts/crmContext'
import { ModalKind, useModal } from '../../contexts/modalContext'
import { useNoteWeeksQuery, useStatusesQuery, useProjectsQuery } from '../../hooks/graphql'
import WeekNoteSection from '../WeekNoteSection'
import TimeSlots from './TimeSlots'
import { CalendarDay, CalendarTimeSlot } from './useCalendarData'
import { SidebarStatus } from '../../pages/StartPage/StartPage'

enum AppointmentStatus {
  Avisiert = 'Avisiert',
}

interface Props {
  weekNumber: number
  days: CalendarDay[]
  isEditingOfftimes: boolean

  selectedDayInWeek: Date | null

  onDayClick?: (day: CalendarDay) => void
  onTimeSlotClick: (slot: CalendarTimeSlot) => void
  setRouteForAppointment?: React.Dispatch<React.SetStateAction<boolean>>
  setShouldUpdateDataItem?: React.Dispatch<React.SetStateAction<boolean>>
  sidebarStatus?: SidebarStatus
}

const CalendarWeek: React.FC<Props> = ({
  weekNumber,
  days,
  isEditingOfftimes,

  onDayClick,
  onTimeSlotClick,
  selectedDayInWeek,
  setRouteForAppointment,
  setShouldUpdateDataItem,
  sidebarStatus,
}) => {
  const { year } = useCrmContext()
  const { data: noteWeeks, isLoading: noteWeeksLoading } = useNoteWeeksQuery()
  const { data: statuses } = useStatusesQuery()
  const { openModal } = useModal()
  const { data: projects } = useProjectsQuery()

  const UNPROCESSED_STATUS_ID = useMemo(() => statuses?.forAppointments?.find((each) => each.label === AppointmentStatus.Avisiert)?.id, [statuses])

  const noteForSelectedWeek = noteWeeks?.find(({ week, year: _year }) => week === String(weekNumber) && _year === String(year))?.note

  const onDeleteOfftimesOfWholeDay = useCallback(
    (day: CalendarDay) => {
      const offtimesToDelete: Offtime[] = []

      day.timeslots.forEach((slot) => {
        if (slot.type === 'offtime') {
          offtimesToDelete.push(slot.data)
        }
      })

      const uniqueOfftimes = [...new Set(offtimesToDelete)]

      if (uniqueOfftimes?.length) {
        openModal({ kind: ModalKind.OfftimesDelete, offtimes: uniqueOfftimes })
      }
    },
    [openModal],
  )

  return (
    <React.Fragment key={weekNumber}>
      <WeekItem
        weekNumber={Number(weekNumber)}
        appendComponent={
          !noteWeeksLoading && !noteForSelectedWeek ? (
            <button
              onClick={() => openModal({ kind: ModalKind.WeekNoteCreate, week: weekNumber, year })}
              className="flex ml-2 items-center h-fit px-0.5 py-0.5 rounded-md text-gray-400 hover:bg-blue-200 hover:text-blue-700 focus:border-gray-300 active:text-blue-800 focus:outline-none transition ease-in-out duration-150"
            >
              <PlusSignIcon dimension="28" />
            </button>
          ) : null
        }
      >
        {noteWeeks && noteForSelectedWeek && (
          <>
            <WeekNoteSection
              note={noteForSelectedWeek}
              onUpdate={() => openModal({ kind: ModalKind.WeekNoteUpdate, week: weekNumber, year })}
              onDelete={() => openModal({ kind: ModalKind.WeekNoteDelete, week: weekNumber, year })}
            />
            <WeekNoteDivider />
          </>
        )}

        {Object.entries(days).map(([_dayNumber, day]) => {
          //has offtime that spans the whole day
          const fullDayOfftime = day.timeslots.find((slot) => slot.type === 'offtime' && slot.data.whole_day)
          const appointmentCount = day.visits.length + day.timeslots.filter((slot) => slot.type === 'appointment').length
          const isExcused = Boolean(fullDayOfftime || appointmentCount === 0)

          const appointments: Appointment[] = []
          const offtimes: Offtime[] = []

          const dateSortFn = (a: CalendarTimeSlot, b: CalendarTimeSlot) => {
            const dateA = new Date(a.date).getTime()
            const dateB = new Date(b.date).getTime()
            return dateA - dateB
          }

          day.timeslots.sort(dateSortFn).forEach((slot) => {
            if (slot.type === 'appointment') {
              appointments.push(slot.data)
            }
            if (slot.type === 'offtime') {
              offtimes.push(slot.data)
            }
          })

          const visits = day.visits
          const slots = day.timeslots

          const appointmentItems = slots.filter((item) => item.type === 'appointment')

          const countOrderItems = (appointmentItems: CalendarTimeSlot[], projects: Project[] | undefined) => {
            let totalOrderItems = 0

            for (const appointmentItem of appointmentItems) {
              if (appointmentItem.type === 'appointment' && appointmentItem.data) {
                for (const orderItem of appointmentItem.data.order_items) {
                  const project = projects?.find((p) => p.id == orderItem.project_id)
                  if (project) {
                    if (isPast(new Date(appointmentItem.date)) && appointmentItem.data.status?.id === '3') {
                      if (project.booked_time_in_seconds >= 3600) {
                        totalOrderItems += orderItem.amount * 2 // Count as 2 times the amount
                      } else {
                        totalOrderItems += orderItem.amount // Count as 1 times the amount
                      }
                    } else if (!isPast(new Date(appointmentItem.date))) {
                      if (project.booked_time_in_seconds >= 3600) {
                        totalOrderItems += orderItem.amount * 2 // Count as 2 times the amount
                      } else {
                        totalOrderItems += orderItem.amount // Count as 1 times the amount
                      }
                    }
                  }
                }
              }
            }
            return totalOrderItems
          }

          const totalSlotsPerDay = countOrderItems(appointmentItems, projects)

          const visitIsProcessed = visits.length ? visits.every((each) => each?.status?.id !== UNPROCESSED_STATUS_ID) : isExcused

          const areAppointmentsProcessed = appointments.length ? appointments.every((each) => each?.status?.id !== UNPROCESSED_STATUS_ID) : isExcused

          const showIndicator = isPast(day.date) && !isExcused

          const haveOfftimes = !!day.timeslots.filter((slot) => slot.type === 'offtime')?.length

          const showOfftimesDeleteButton = haveOfftimes && isEditingOfftimes
          return (
            <DayItem
              countOfReminders={day.tasks?.length}
              id={`week-${weekNumber}-day-${_dayNumber}`}
              onClick={onDayClick ? () => onDayClick(day) : () => null}
              selected={selectedDayInWeek ? isSameDay(selectedDayInWeek, day.date) : false}
              key={`${day.date.toUTCString()}-dayItem`}
              date={day.date}
              visits={day.visits.length}
              deleteIcon={{
                show: showOfftimesDeleteButton,
                onClick: () => onDeleteOfftimesOfWholeDay(day),
              }}
              appointmentIsProcessed={areAppointmentsProcessed}
              visitIsProcessed={visitIsProcessed}
              showIndicator={showIndicator}
              totalSlotsPerDay={totalSlotsPerDay}
              showSlots={true}
            >
              <TimeSlots
                key={day.date.toUTCString()}
                day={day.date}
                onClick={onTimeSlotClick}
                timeSlots={day.timeslots}
                setRouteForAppointment={setRouteForAppointment}
                sidebarStatus={sidebarStatus}
                setShouldUpdateDataItem={setShouldUpdateDataItem}
              />
            </DayItem>
          )
        })}
      </WeekItem>
    </React.Fragment>
  )
}

const WeekNoteDivider = () => (
  <div className="flex">
    <div className={`flex w-15pc border-b border-gray-300 ml-2 pb-3 text-base font-body items-center`}></div>
    <div className={`flex flex-wrap w-80pc pb-3 border-b border-gray-300 `}></div>
    <div
      className={`text-base w-5pc flex ml-2 pb-3 border-b border-gray-300 justify-center items-center 
      text-gray-400`}
    ></div>
  </div>
)

export default CalendarWeek
