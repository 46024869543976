import React, { useMemo } from 'react'
import { format, isBefore } from 'date-fns'
import { ModifiedAppointmentType } from './InfoContainerTabs'
import { useCrmContext } from '../../contexts/crmContext'

type HistoryDataType = { date?: string; participantCount: number; info: string; trainer: string | undefined }

export const HistoryInfo = ({ appointments }: { appointments: ModifiedAppointmentType[] }) => {
  const { isUserCallcenter } = useCrmContext()
  const historyData = useMemo(() => {
    let data: HistoryDataType[] = []

    appointments.forEach(({ date: rawDate, isCancelled, isVisit, isCompleted, finished_presentations, status }) => {
      const date = rawDate ? format(rawDate, 'dd.MM.yyyy') : undefined

      if (rawDate && !isBefore(rawDate, new Date())) {
        return
      }

      if (isCompleted) {
        const finished =
          finished_presentations?.reduce((result: HistoryDataType[], current) => {
            const existingEntry = result.find((entry) => entry.trainer === current.trainer.name)

            if (existingEntry) {
              existingEntry.participantCount += current.adjusted_number_of_participants
              if (!existingEntry.info.includes(current?.project?.name ?? '')) {
                existingEntry.info += `, ${current?.project?.name ?? ''}`
              }
            } else {
              result.push({
                date,
                participantCount: current.adjusted_number_of_participants,
                info: current?.project?.name ?? '',
                trainer: current.trainer.name,
              })
            }

            return result
          }, []) ?? []

        data = data.concat(finished)
      }

      if (isCancelled || isVisit) {
        data.push({
          date,
          participantCount: 0,
          trainer: undefined,
          info: isCancelled ? 'abgesagt' : `Besuch: ${status?.label}`,
        })
      }
    })

    return data
  }, [appointments])

  return (
    <>
      {!historyData.length && <div>Keine Einträge vorhanden</div>}
      {isUserCallcenter && historyData.length ? (
        <div>
          {`${historyData[historyData.length - 1].date} - ${historyData[historyData.length - 1].participantCount} Tln - ${
            historyData[historyData.length - 1].trainer ? historyData[historyData.length - 1].trainer : historyData[historyData.length - 1].info
          }${historyData[historyData.length - 1].trainer ? ' - ' + historyData[historyData.length - 1].info : ''}`}
        </div>
      ) : (
        historyData.map(({ date, participantCount, trainer, info }, idx) => (
          <div key={idx}>{`${date} - ${participantCount} Tln - ${trainer ? trainer : info}${trainer ? ' - ' + info : ''}`}</div>
        ))
      )}
    </>
  )
}
